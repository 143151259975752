import React, { useState } from 'react'

const About = ({ }) => {

    const [tagName, setTagName ] = useState('')
    const toggleTag = (name) => {
        setTagName(name)
    };
    
    return (

        <div>
            <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous"></link>
            <div className="column has-text-left my-6 mr-6">
                <h1 className="title is-1">Modify and you shall find all your audience queries.</h1>
                <div>
                    <p className="is-size-4 my-5">
                        <strong>Why keywords are a beacon to marketing efforts?</strong><br />
                        In the dynamic world of digital marketing, where success hinges on understanding your audience,
                        <br /> one aspect remains constant: The importance of keywords. <br />Whether you're navigating SEO content pieces or fine-tuning your PPC campaigns,
                        keywords are your guiding stars. Nowadays, many keyword tools offer solutions for sorting bulk keywords, analysing trends,
                        alert market changes, competitors actions, and NLP processing, and these indeed address
                        many roadblocks in understanding user-queries and implementing relevant actions in marketing campaigns.
                        Yet, generating keywords lists is still a limited step as dynamic research is not exactly accessible in a simple easy way.
                    </p>
                    <p className="is-size-4 my-5">
                        <strong>Why are keyword lists a cornerstone?</strong><br />
                        Reaching out to fresh keywords lists is a fundamental aspect of keyword research
                        as it involves creating a comprehensive inventory of words and phrases that
                        serve as the foundation for decision making and action items in SEO, PPC advertising, content creation, and more.
                        It's not just about creating a static list; It is inevitably a continuous task
                        as the sheer volume of search queries generated daily is getting bigger,
                        some search queries may be ambiguous, vague or influenced by seasonal trends,
                        events, and news and users might phrase their queries in different ways,
                        in different locations, leading to muktiple keyword variations.
                    </p>
                    <p className="is-size-4 my-5">
                        <strong>Is generating keyword lists unlimited?</strong><br />
                        Over time keyword research and optimization were developed to include,
                        in different degrees, user-intent categories, keyword classifiers, and modifiers as
                        these are playing a crucial role in discovering search terms, stay attuned to shifts
                        in user behaviour, industry trends, market changes, and search engine algorithms.
                        Tapping freely into search terms with custom modifiers is empowering these processes
                        and with Searchedwords.site <strong>it can be done easily.</strong>
                    </p>
                    <p className="is-size-4 my-5">
                        <strong>How to generate unique keywords lists?</strong><br />
                        <a className="is-size-4" href="https://searchedwords.site">Searchedwords.site</a> is an online tool that lets you combine a seed keyword
                        with a custom modifier. You can combine any word, noun, adjective, niche term,
                        material, slang, pronoun, shopping term, verb, question, preposition and any
                        search term your audience might be searching for. <a className="is-size-4" href="https://searchedwords.site">Searchedwords.site</a> then curates
                        from search engines’ databases all searched words related to your custom search
                        into a list. These audience queries are what people have searched for.
                    </p>
                    <p className="is-size-4 my-2">
                        <strong>Examples ahead:</strong>
                    </p>
                    <p className="is-size-4">View search results of the following combinations:</p>
                    <p className="is-size-4">[Seed keyword - Custom modifiers]</p>
                    <br />
                    <article className="message is-link">
                        <div className="message-header is-clickable" onClick={() => toggleTag("Muscle")}>
                            <p>Muscle - Protein, Carbohydrates, Fats, Vitamins, Minerals (click to view 102 keywords)</p>
                        </div>
                        <div className={tagName === "Muscle" ? "message-body" : "message-body is-hidden"}>
                            muscle protein synthesis,muscle protein,muscle protein powder,muscle protein breakdown,
                            muscle protein shake,muscle protein name,muscle protein synthesis window,muscle protein synthesis after workout,
                            muscle protein synthesis meaning,muscle protein food,muscle proteins,muscle protein turnover,muscle protein for dogs,
                            muscle protein bar,muscle protein alignment,muscle protein synthesis definition,muscle protein synthesis graph,
                            beverly international ultimate muscle protein,earth fed muscle protein,max muscle protein powder,
                            ultimate muscle protein,muscle protein shakes,lean muscle protein shake,monster muscle protein,muscle carbohydrates are,
                            muscle carbohydrates glycogen,carbohydrates muscle recovery,carbohydrates muscle protein synthesis,
                            carbohydrates muscle hypertrophy,carbohydrates muscle cramps,carbohydrates muscle repair,carbohydrates muscle sparing,
                            carbohydrates muscle aches,muscle milk carbohydrates,calcium deposits under muscles carbohydrates,muscle building carbohydrates,
                            what builds muscle carbs or protein,muscle gaining carbohydrates,glycosyn max muscle carbs,pure muscle carbs,muscle carbs,
                            muscle carbohydrate metabolism,muscle fats,good fats muscle building,muscle gain healthy fats,does muscle eat fat,
                            is muscle fat good,do muscles consume fat,muscle facts,muscle facts for kids,muscle fat storage,muscle fastigheter,
                            muscle fast twitch,muscle facts ks2,muscle fascia,muscle fascicle,muscle fasciculation,muscle fast growth,muscle fatigue,
                            muscle fastigheter ab,muscle become fats,muscle vitamins,muscle vitamins supplements,muscle vitamins and minerals,
                            vitamins muscle growth,vitamins muscle cramps,vitamins muscle recovery,vitamins muscle pain,vitamins muscle spasms,
                            vitamins muscle twitching,vitamins muscle soreness,muscle vitamins for men,muscle vitamins for women,muscle vitamins for dogs,
                            muscle vitamins supplements women,best vitamins for weight loss and muscle gain,vitamins for muscle cramps,
                            vitamins for muscle growth,joint and muscle vitamins,wile e coyote leg muscle vitamins,tony horton muscle vitamins,
                            tight calf muscle vitamins,best muscle vitamins,kaged muscle vitamins,bone and muscle vitamins,muscle minerals,
                            minerals muscle cramps,muscle contraction minerals,muscle building minerals,muscle recovery minerals,
                            muscle relaxant minerals,muscle shoals minerals,muscle strength minerals,muscle pain minerals,muscle gain minerals,
                            muscle building vitamins and minerals,muscle cramps minerals,muscle twitch minerals,muscle tightness and minerals,
                            muscle contraction and minerals,muscle aches and minerals,muscle cramp minerals</div>
                    </article>
                    <article className="message is-warning">
                        <div className="message-header is-clickable" onClick={() => toggleTag("Dress")}>
                            <p>Dress - Christmas, Independence Day, Easter, Diwali, Passover (click to view 90 keywords)</p>
                        </div>
                        <div className={tagName === "Dress" ? "message-body" : "message-body is-hidden"}>
                            dress christmas tree,dress christmas,dress christmas party,dress christmas girl,
                            dress christmas day,dress christmas table,dress christmas shirts,dress christmas blue,
                            dress christmas long,dress christmas party uk,dress christmas tree for sale,
                            dress christmas santa,dress christmas ornaments,dress christmas sweater,
                            dress christmas tree sam's club,dress christmas outfits for women,
                            grace mitchell dress christmas tree,red dress christmas party,women sweater dress christmas,
                            men's dress christmas shirts,cocktail dress christmas party,dress christmas tree mannequin,
                            plaid dress christmas,how to make a dress christmas tree,girls dress christmas,
                            megan young new york gown independence day,dress code on independence day,
                            dress for independence day,dress independence day,fancy dress independence day,
                            dress for independence day dance,dress for independence day online,independence clothing day,
                            dress code for independence day,dress code for independence day in office,
                            dress up for independence day,dress ideas for independence day,dress design for independence day,
                            dress easter,dress easter purses for little girls,dress master,dress ester,
                            dress master private limited,dress master sewing machine,dress master tailors,
                            dress master battaramulla,dress master negombo,dress master wattala,dress master apparel pvt ltd,
                            far eastern matriarch's dress,leann rimes dress easter 2023 images,baby dress easter shoes girls with diamonds,
                            yellow dress easter,pink and blue floral dress easter,gypsy tradition dress eastern europe,
                            how to make a black dress easter,maternity dress easter,maxi dress easter,dress easter hats for women,
                            coral dress easter,dress easter pastel,dress easter shirts,easter dress toddler,easter dress girl,
                            easter dress baby girl,eastern dress,easter dress baby,easter dress 2023,easter dress up,
                            easter dress for women,shop easter dress for girls,indian traditional.dress diwali,dress code diwali,
                            dress for diwali,dress code for diwali,dress diwali,diwali dress ideas,diwali dress code,
                            diwali dress nyt,diwali dress for baby girl,diwali dress for 15 year girl,diwali dress for 13 year girl,
                            diwali dress myntra,diwali dress for baby boy,diwali dress name,dress for passover,passover dress code,
                            passover dress,passover dress up,baby passover dress
                        </div>
                    </article>
                    <article className="message is-primary">
                        <div className="message-header is-clickable" onClick={() => toggleTag("Locksmith")}>
                            <p>Acne - hair, diet, detox, blood, biology (click to view 101 keywords)</p>
                        </div>
                        <div className={tagName === "Locksmith" ? "message-body" : "message-body is-hidden"}>
                            acne hairline,acne hair follicle,acne hair loss,acne hair growth,acne hair,
                            acne hair treatment,acne hairline back of neck,acne hair removal,acne hair shampoo,
                            aube hair acne 長岡京店,acne hair loss weight gain,acne hairline neck,acne hair loss irregular periods,
                            scalp acne hair loss,fungal acne hairline,shampoo for acne hairline,acne hair gel,
                            thyroid acne hair loss,hormonal acne diet,acne diet plan,acne diet natural treatment,
                            cystic acne diet,acne diet for teens,hormonal imbalance acne diet,hormonal cystic acne diet,
                            nodular acne diet,best acne diet,dr mcdougall acne diet,acne diet,acne diet meal plan,
                            acne diet pdf,acne diet reddit,acne diet recipes,acne dietitian,acne diets that work,
                            acne dietary supplements,acne diet book,acne diet food list,acne diet and nutrition,
                            acne diet chart,acne diet menu,acne diet triggers,acne dietary supplement,acne diets,
                            acne detox drink,acne detox,acne detox diet,acne detox facial,acne detox water,
                            acne detox juice,acne detox water for skin,acne detox smoothie,acne detox food,
                            acne detox mask,acne detox pills,acne detox tea,acne detoxificar,acne detox diet plan,
                            acne detox diet cleanse,acne detox bath,acne detox soap,medical medium acne detox,acne detox program,
                            safeguard acne detox,anti acne detox,acne blood test,acne blood,acne blood pressure medication,
                            acne blood clot,acne blood sugar,acne blood spot,acne blood work,acne blood vessels on face,
                            what causes blood acne,why is my acne filled with blood,acne blood pressure,acne blood under skin,
                            acne blood bump,acne blood blisters,acne spot dragon blood gel,hormonal acne blood test,
                            acne blood blister,inflamed cystic acne bloody removal videos,acne blood treatment,
                            hormonal acne blood work,cystic acne blood,face acne blood,acne blood tests,acne biology,
                            acne meaning in biology,what causes acne biology,acne biology articles,
                            why acne and pimples are common in adolescence short answer,eczema biology,
                            ace biology lab,ace biology,ace biology lab videos,ache biology,ace biology dvd,
                            ace biology videos,ace biology lab 1097</div>
                    </article>
                    <p className="is-size-4 my-5">
                        <strong>Is custom keyword research beneficial?</strong><br />
                        Specifying keyword research with modifiers clarifies user-intent,
                        reducing the noise in collected data and provides insights that are more actionable.
                        With relevant accurate queries, it’s much more possible, more to say, accessible
                        to identify content gaps, identify content gaps, long-tail keywords and variations, trends and user interests.
                        These hidden gems suddenly come to light, clear as day, guiding your strategies with precision.
                    </p>
                    <p className="is-size-4 my-5">
                        <strong>Your audience searched words at your fingertips</strong><br />
                        Generating keyword lists is a strategic process aimed at understanding user behaviour,
                        anticipating their needs, and optimizing your online presence to meet those needs
                        effectively. Don't let the complexity or volume of keywords hold you back.
                        Instead of querying data manually, use <a className="is-size-4" href="https://searchedwords.site">Searchedwords.site</a> to quickly gather
                        a comprehensive list of related queries in a more organized manner.
                        This advanced modifiers research injects creativity and freedom to
                        keyword research and optimization. Moreover it saves time and energy,
                        granting more focus on strategy adjustments and implementation.

                    </p>
                    <div>

                        <p className="is-size-4">
                            Happy researching.
                        </p>
                    </div>
                </div>

            </div>

            
        </div>

    )
}


export default About;