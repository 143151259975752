import React from 'react'

const About = ({ }) => {
    return (

        <div>
            <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous"></link>
            <section className="hero is-fullheight">
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div className="columns is-8 is-variable ">
                            <div className="column is-two-thirds has-text-left">
                                <h1 className="title is-1">About</h1>
                                <div>

                                    <p className="is-size-4">
                                        Hi, I'm Gal, thanks for visiting searchedwords.site!
                                        <br />
                                        </p>
                                    <p className="is-size-4">
                                        searchedwords.site is aim to empower ppc, seo and content freelancers and teams by combining seed keyword with related (and unrelated) terms.
                                        You can specify or extend your keyword research with any context and within a click the automation generates segmented keyword lists based on the search engines' databases.
                                        This tailored research grants more control and creativity to your marketing efforts.
                                        <br />
                                    </p><br />
                                    <p className="is-size-4">
                                        <strong>About me</strong><br />
                                        I am a developer and an entrepreneur (and a wife, and a mom, and many things).
                                        In <strong>April 2020</strong> I googled 'how to learn to code' and now I am a full-stack developer,
                                        coding automations, systems and solutions full-time in product-operations department. Before that, I was a marketer and experienced with SEO,
                                        content, PPC, and social media in management/hands-on positions for +7 years.</p>
                                        <br />
                                    <div>
                                        <p className="is-size-4">Please let me know if there's anything I can help with.</p>
                                        <br />
                                    <p className="is-size-4">Cheers, Gal.</p>
                                    </div>


                                </div>
                                <div className="my-4">
                                    <a href="mailto:galdevops@gmail.com" target="_blank" className="button is-light is-large"><i className="fa fa-envelope" aria-hidden="true"></i></a>
                                    <a href="https://www.linkedin.com/in/gya-ish-b5b463a0/" target="_blank" className="button is-light is-large mx-4"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                                </div>
                                
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

const mapStateToProps = state => ({});

export default About;