import axios from 'axios';
import {
    QUERY_REQ,
    QUERY_REQ_FAIL,
    QUERY_REQ_RESULTS,
    QUERY_REQ_RESULTS_FAIL,
    SELECTED_RESULTS,
    QUERY_OUTLINE,
    QUERY_ARTICLE,
    QUERY_CONTENT_FAIL,
    QUERY_CONTENT_RESET,
    USER_SEGMENTS,
} from "../actions/queryTypes.js"



export const validate_token = () => async => {
    if (!axios.defaults.headers.common["Authorization"]){
        if (localStorage.getItem('access')) {
            axios.defaults.headers.common["Authorization"] = "Token " + localStorage.getItem('access');
        }
    }
}
    


export const generate_query_request = (keyword, segments) => async dispatch => {
    if (!axios.defaults.headers.common["Authorization"]){
        if (localStorage.getItem('access')) {
            axios.defaults.headers.common["Authorization"] = "Token " + localStorage.getItem('access');
        }
    }
    let msg = 'Unable to complete request. Please try again.'
    const body = { keyword, segments };
    dispatch({
        type: 'RESET_QUERY_REQ',
        payload: true
    })

    try {
        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/kuery/`, body);
        let str_v = result.data.detail || 'ok'
        if (!str_v.includes('throttled')) {
            dispatch({
                type: 'RESET_QUERY_REQ',
                payload: false
            })
        }
        

        const { req_ids } = result.data;
        dispatch({
            type: QUERY_REQ,
            payload: req_ids
        })
        return result.data
        
    } catch (err) {
        dispatch({
            type: QUERY_REQ,
            payload: err.response.data
        })
        return err.response.data
    }

};



export const fetch_query_results = (requests_ids) => async dispatch => {
    validate_token()
    let msg = 'Unable to complete research. Please try again.'
    const body = { requests_ids }
    
    try {

        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/queries/`, body);
        dispatch({
            type: QUERY_REQ_RESULTS,
            payload: result.data
        })
        
    } catch (err) {
        dispatch({
            type: QUERY_REQ_RESULTS_FAIL,
            payload: msg
        })
    }

};



export const add_segment = (name, type, modifiers) => async dispatch => {
    
    if (!axios.defaults.headers.common["Authorization"]){
        axios.defaults.headers.common["Authorization"] = "Token " + localStorage.getItem('access');
    }
    const body = { name, type, modifiers };

    try {
        await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/add_segment/`, body);
        
    } catch (err) {
        return err
        // console.log('add_segment err: ', err)
    }

};



export const edit_segment = ( segment_id, modifiers) => async dispatch => {
    
    if (!axios.defaults.headers.common["Authorization"]){
        axios.defaults.headers.common["Authorization"] = "Token " + localStorage.getItem('access');
    }

    const body = { segment_id, modifiers };

    try {
        await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/edit_segment/`, body);
        
    } catch (err) {
        return err
        // console.log('edit_segment err: ', err)
    }

};




export const load_segments = ( segments ) => async dispatch => {
    
    if (!axios.defaults.headers.common["Authorization"]){
        axios.defaults.headers.common["Authorization"] = "Token " + localStorage.getItem('access');
    }

    const body = { segments };

    try {
        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/segments/`, body);
        dispatch({
            type: USER_SEGMENTS,
            payload: result.data
        })

    } catch (err) {
        return err
        // console.log('load_segments err: ', err)
    }

};






export const updateSelected = ( selectedData ) => async dispatch =>  {
    const body = { selectedData };
    dispatch({
        type: SELECTED_RESULTS,
        payload: body
    })
}



export const generate_query_content = (query, tone, hook, length) => async dispatch => {
    validate_token()
    let msg = 'Unable to complete request. Please try again.'
    const body = { query, tone, hook, length }
    
    try {
        dispatch({
            type: QUERY_CONTENT_FAIL
        })

        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/query_content/`, body);
        // dispatch({
        //     type: QUERY_OUTLINE,
        //     payload: result.outline
        // })
        let data = {
            article: result.data.article,
            details: result.data.details
        }
        dispatch({
            type: QUERY_ARTICLE,
            payload: data
    
        })
        
    } catch (err) {
        dispatch({
            type: QUERY_CONTENT_FAIL,
            payload: msg
        })
        return err.response.data
    }

};



export const generate_sw_request = (keyword, modifiers) => async dispatch => {
    if (!axios.defaults.headers.common["Authorization"]){
        if (localStorage.getItem('access')) {
            axios.defaults.headers.common["Authorization"] = "Token " + localStorage.getItem('access');
        }
    }
    let msg = 'Unable to complete request. Please try again.'
    const body = { keyword, modifiers };
    dispatch({
        type: 'RESET_QUERY_REQ',
        payload: true
    })

    try {
        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/kuery/`, body);
        let str_v = result.data.detail || 'ok'
        if (!str_v.includes('throttled')) {
            dispatch({
                type: 'RESET_QUERY_REQ',
                payload: false
            })
        }
        

        const { req_ids } = result.data;
        dispatch({
            type: QUERY_REQ,
            payload: req_ids
        })
        return result.data
        
    } catch (err) {
        dispatch({
            type: QUERY_REQ,
            payload: err.response.data
        })
        return err.response.data
    }

};







export const contact_form_submitted = (email, message) => async dispatch => {
    

    const body = { email, message };

    try {
        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/contact/`, body);

    } catch (err) {
        return err
        // console.log('load_segments err: ', err)
    }

};