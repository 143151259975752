import React, { useState } from 'react'
import { connect } from "react-redux";
import { useNavigate  } from 'react-router-dom';
import { generate_sw_request, load_segments } from "actions/queryActions"

const SearchwordForm = ({ load_segments, generate_sw_request, user, segments, title, subtitle, props , queryRequests}) => {

    if (user && !segments) {
        load_segments(user?.searchsegment_set);
    }

    const navigate = useNavigate();
    
    const [errMsg, setErrMsg] = useState('')
    const [segmentList, setSegmentList] = React.useState([])
    const [keyword, setKeyword] = React.useState('')
    const [modifiers, setModifiers] = React.useState('')

    const handleSelect = (e) => {
        const { value } = e.target
        let is_exist = segmentList.includes(value)
        
        if (is_exist) {
            setSegmentList(segmentList.filter(item => item !== value));
        } else {
            setSegmentList([...segmentList,value])
        }
        
    }

    const handleChange = (e) => {
        const { value } = e.target
        if (e.target.name == 'keyword'){
            setKeyword(value)
        } else {
            setModifiers(value)
        }
            
    };


    const handleSWSubmit = (e) => {
		e.preventDefault();
        setErrMsg('')
        let listMod
        if (modifiers.indexOf(',') > -1) { 
            listMod = modifiers.split(',')
        }

        if (listMod && listMod.length > 5){
            setErrMsg('Up to 5 modifiers.')
        } else {
            let seedKeyword = keyword
            let queryReq = generate_sw_request(
                keyword,
                modifiers)


                queryReq.then(
                    (value) => {
                        setKeyword('')
                        setModifiers('')
                        let str_v = value.detail || 'ok'
                        if (str_v.includes('throttled')) {
                            setErrMsg('Daily rate limit exceeded.')
                        } else {
                            navigate(`/?q=${seedKeyword}`);

                        }
                        
                    },
                    (reason) => {
                        setErrMsg(reason)
                    },
                );
        }
    };


    return (

        <div>
                    
                        <div>
                        {errMsg.length > 0 &&
                                    <div className="notification is-small is-warning is-light is-size-6 p-2 mt-4 has-text-left">
                                        {errMsg}
                                    </div>
                                }
                                
                            <form className="boxborder">
                                <div className="columns is-justify-content-center">
                                    <div className="column is-one-third">
                                        <label className="is-block mb-4">
                                        <span className="is-block mb-2">Keyword</span>
                                        <input
                                            name="keyword"
                                            className="input"
                                            type="text"
                                            placeholder="Enter your keyword..."
                                            value={keyword}
                                            onChange={handleChange}
                                        />
                                        </label>
                                    </div>
                                <div className="column is-one-third">
                                    <label className="is-block mb-4">
                                    <span className="is-block mb-2">Modifiers (Up to 5 | separated by a common)</span>
                                    <input
                                        name="modifiers"
                                        className="input"
                                        type="text"
                                        placeholder="Enter modifiers..."
                                        value={modifiers}
                                        onChange={handleChange}
                                    />
                                    </label>
                                </div>
                                
                                <div className="column is-one-third">
                                <label className="is-block">
                                <span className="is-block desk-m"></span>
                                
                                <button
                                        className="button is-link px-4 desk-mb"
                                        onClick={handleSWSubmit}
                                    >
                                        Search
                                    </button>
                                </label>

                                </div>
                                

                                </div>
                                
                                
                                

                                
                                
                            </form>
                        </div>
                    
                

        </div>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    queryRequests: state.queryReducer.queryRequests,
    segments: state.queryReducer.segments
  });
  
  export default connect(mapStateToProps, { generate_sw_request, load_segments })(SearchwordForm);