import { React, useState } from 'react'
import { connect } from "react-redux";
import Logo from '../assets/searched_words.png'
import MenuIcon from '../assets/ellipsis-v-solid.svg'
import { logout } from "../actions/authActions"

const Topbar = ({ logout, user }) => {
    const [isActive, setIsActive] = useState(false)

    const toggle = () => {
        if (!isActive) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }

    return (

        <div>
            <nav className="navbar has-shadow is-fixed-top">
                <div className="navbar-brand">
                    <div className="theme">
                        <a
                            href="/"
                            className="navbar-item px-0">
                            <img src={Logo} alt="site logo" />
                        </a>
                    </div>

                    <div className="menuItem columns">
                    <div className="column is-hidden-mobile">
                            <a
                                className="button is-warning mr-4"
                                href="/guide"
                            >
                                Monthly Subscription Guide
                            </a>

                            </div>
                        <div className="navbar-item px-0 column">
                            <div className={`dropdown is-right ${isActive && 'is-active'}`} >
                                <div className="dropdown-trigger">
                                    <button onClick={toggle} className="button menuBtn" aria-haspopup="true" aria-controls="dropdown-menu6">
                                        <span className="icon">
                                            <img src={MenuIcon} width="7" height="7" alt="site bar menu" />
                                        </span>
                                    </button>

                                </div>
                                <div className="dropdown-menu" id="dropdown-menu6" role="menu">
                                    <div className="dropdown-content">
                                        <a href='/account' className="dropdown-item">
                                            {user ? `Hi ${user?.first_name}` : "Account"}
                                        </a>
                                        {user &&
                                            <a href="/segments" className="dropdown-item">
                                                Segments
                                            </a>
                                        }
                                        <a href="/guide" className="dropdown-item">
                                            Guide
                                        </a>
                                        <a href="/survey" className="dropdown-item is-link">
                                            Take a survey
                                        </a>
                                        <a href="/about" className="dropdown-item">
                                            About
                                        </a>
                                        <a href="/terms" className="dropdown-item">
                                            Terms
                                        </a>
                                        {user &&
                                            <a role="button" onClick={logout} className="dropdown-item">
                                                Logout
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </nav>
        </div>
    )
}
const mapStateToProps = state => ({
    user: state.authReducer.user
});

export default connect(mapStateToProps, { logout })(Topbar);