import React, { useState } from 'react'
import { connect } from "react-redux";
import { contact_form_submitted } from "../actions/queryActions";

// https://www.npmjs.com/package/source-map-explorer

const ContactForm = ({ contact_form_submitted }) => {
    const [formData, setFormData] = useState({
        email: "",
        message: "",
    })

    const [submitted, setSubmitted] = useState(false)

    const handleChange = (e) => {

        const { value } = e.target

        setFormData((prevValue) => {
            if (e.target.name === "email") {
                return {
                    email: value,
                    message: prevValue.message
                }
            } else {
                return {
                    email: prevValue.email,
                    message: value
                };
            }
        }
        )
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let sendForm = contact_form_submitted(formData.email, formData.message)
        
        sendForm.then(
            (value) => {
                setFormData({
                    email: "",
                    message: "",
                })
                
                setSubmitted(true)

            },
            (reason) => {
                console.log(reason)
            },
        );

    }

    return (
        <div>

                <div className="columns is-justify-content-left">
                    <div className="column is-6-tablet is-5-desktop is-4-widescreen is-3-full">
                        {
                            submitted &&
                            <div className="notification is-small is-success is-light is-size-6 py-2 px-2 has-text-left">
                                Message sent! Thank you.
                            </div>
                        }
                        <form className="box p-5">
                            <label className="is-block mb-4">
                                <span className="is-block mb-2">Email address</span>
                                <input
                                    name="email"
                                    className="input"
                                    type="email"
                                    placeholder="Enter your email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </label>

                            <label className="is-block mb-4">
                                <span className="is-block mb-2">Message</span>
                                <textarea

                                    name="message"
                                    className="textarea"
                                    placeholder="Write your message"
                                    required
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </label>


                            <div className="mb-4">
                                <button
                                    className="button is-link px-4"
                                    onClick={handleSubmit}
                                >
                                    Send
                                </button>

                            </div>

                        </form>
                    </div>
                </div>
        </div>
        

    )
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { contact_form_submitted })(ContactForm);
