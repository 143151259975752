import React from 'react'
import { connect } from "react-redux";
import ContactForm from 'components/ContactForm';

const Footer = ({ queryResults, contentDetails }) => {
    const today = new Date();
    const year = today.getFullYear();

    return (

        <div className="footer">
            <div>
                <footer>
                    <div className="has-text-left footertheme">
                        <div className="subtitle"> Have a request / feedback?</div>
                        <ContactForm />
                        <hr />
                        <div>
                            <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                                    integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous"></link>
                            <div className="columns">
                                <div className="column">
                                    <p>Copyrights @ {year} searchedwords.site. All rights reserved.</p>
                                    <a href="/terms">Terms & Privacy</a> | <a href="/about">About</a>

                                </div>
                                <div className="column has-text-right is-hidden-mobile">
                                    <a href="https://www.reddit.com/submit?url=https://searchedwords.com" target="_blank" rel="noreferrer" className="button is-link mr-2"><i className="fa fa-reddit" aria-hidden="true"></i></a>
                                    <a href="https://twitter.com/intent/tweet?text=Generate unique keyword lists with Searchedwords.com" target="_blank" rel="noreferrer" className="button is-link mx-2"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    <a href="https://api.whatsapp.com/send?text=Hi, you can generate unique keyword lists with Searchedwords.com" data-action="share/whatsapp/share" target="_blank" rel="noreferrer" className="button is-link mx-2"><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                                    <a href="http://www.linkedin.com/shareArticle?mini=true&url=https://searchedwords.com&title=Generate unique keyword lists with Searchedwords.com&source=https://searchedwords.com" target="_blank" rel="noreferrer" className="button is-link mx-2"><i className="fa fa-linkedin" aria-hidden="true"></i></a>

                                </div>
                                <div className="column is-hidden-desktop">
                                    <a href="https://www.reddit.com/submit?url=https://searchedwords.com" target="_blank" rel="noreferrer" className="button is-link mr-2"><i className="fa fa-reddit" aria-hidden="true"></i></a>
                                    <a href="https://twitter.com/intent/tweet?text=Generate unique keyword lists with Searchedwords.com" target="_blank" rel="noreferrer" className="button is-link mx-2"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                                    <a href="https://api.whatsapp.com/send?text=Hi, you can generate unique keyword lists with Searchedwords.com" data-action="share/whatsapp/share" target="_blank" rel="noreferrer" className="button is-link mx-2"><i className="fa fa-whatsapp" aria-hidden="true"></i></a>
                                    <a href="http://www.linkedin.com/shareArticle?mini=true&url=https://searchedwords.com&title=Generate unique keyword lists with Searchedwords.com&source=https://searchedwords.com" target="_blank" rel="noreferrer" className="button is-link mx-2"><i className="fa fa-linkedin" aria-hidden="true"></i></a>

                                </div>

                            </div>
                        </div>
                        
                        
                        
                        {/* <a href="/terms">Terms & Privacy</a> | <a href="/guide">Guide</a> | <a href="/survey">Take a survey</a>
                        <div>
                            <img className="payments pt-2" src={Cards} alt="payment methods" />
                        </div> */}
                    </div>
                    
                    
                    {/* <div className="columns
                                        is-justify-space-between
                                        is-align-content-center"
                    >
                        <div className="column">
                        
                        </div>
                        <div className="column is-6-tablet is-5-desktop is-4-widescreen is-3-full">
                            <p>Copyrights @ {year} <a href="mailto:galdevops@gmail.com">galdevops@gmail.com</a></p>
                            <a href="/terms">Terms & Privacy</a>
                        </div>


                    </div> */}
                </footer>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    queryResults: state.queryReducer.queryResults,
    contentDetails: state.queryReducer.contentDetails,
});

export default connect(mapStateToProps, {})(Footer);
