import {
    QUERY_REQ,
    QUERY_REQ_FAIL,
    QUERY_REQ_RESULTS,
    QUERY_REQ_RESULTS_FAIL,
    RESET_QUERY_REQ,
    SELECTED_RESULTS,
    QUERY_OUTLINE,
    QUERY_ARTICLE,
    QUERY_CONTENT_FAIL,
    USER_SEGMENTS,
} from "../actions/queryTypes.js"

const initialState = {
    modifiers: null,
    segments: null,
    contentDetails: null,
    queryArticle: null,
    selectedResults: null,
    queryResults: null,
    queryResultsObj: null,
    resetKuery: false,
    queryRequests: null
};

const QueryReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case SELECTED_RESULTS:
            return {
                ...state,
                selectedResults: payload
                
            }
        case QUERY_REQ:
            return {
                ...state,
                queryRequests: payload,
                queryResults: null
            }
        case QUERY_REQ_FAIL:
            return {
                ...state,
                queryRequests: null
            }
        case RESET_QUERY_REQ:
            return {
                resetKuery: payload,
                queryResults: null,
                queryRequests: null
            }
        
        case QUERY_REQ_RESULTS:
            return {
                ...state,
                queryResults: payload['query_requests_results'],
                queryResultsObj: payload['query_results_object']
            }
        case QUERY_REQ_RESULTS_FAIL:
            return {
                ...state,
                queryResults: null
            }
        case QUERY_ARTICLE:
            return {
                ...state,
                queryArticle: payload['article'],
                contentDetails: payload['details']
            }
        case QUERY_CONTENT_FAIL:
            return {
                ...state,
                queryArticle: null,
                contentDetails: null
            }
        case USER_SEGMENTS:
            return {
                ...state,
                segments: payload['segments'],
                modifiers: payload['modifiers']
            }
        default:
            return state
    }
};


export default QueryReducer;