
import $ from 'jquery'
import 'jquery/dist/jquery.min.js';
import React, { useRef, useState } from "react"
import { connect } from "react-redux";
import { fetch_query_results } from "../actions/queryActions";
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"

// onClick=${handleSubmit}
// https://qawall.in/datatable-select-all-checkbox-using-jquery/
// https://gander.wustl.edu/~wilson/store/yui/docs/datatable/datatable-chkboxselect.html
// https://datatables.net/extensions/select/examples/initialisation/checkbox.html
const QueryResults = ({ fetch_query_results, queryRequests, queryResults, qview }) => {


    $.DataTable = require('datatables.net')
    const tableRef = useRef()
    $(tableRef.current).DataTable(
        {
            pageLength: 100,
            data: queryResults,
            columns: [
                { title: "Select",
                targets: 0,
                data: null,
                defaultContent: "",
                className: "select-checkbox thin-col",
                render: function ( data, type, row, meta ) {
                    return `<input
                            disabled
                            type="checkbox"
                            className="checkboxItem"
                            name="${row[1]}"
                            value="${row[0]}"
                            >`;
                }},
                { title: "Query"},
                { title: "Modifier"},
                { title: "Segment"},
                // { title: "Action",
                //     targets: 0,
                //     data: null,
                //     render: function ( data, type, row, meta ) {
                //         let kwd = row[1].replace(/\s/g, '%20')
                        
                        // return `<button onClick={console.log(${splitStr[1]})}>Download</button>`;

                        // return `<a href=/query_content/?q=${kwd}>Create content</a>`

                        //return `<a href="'+${row[0]}+'"
                        //    target="popup" 
                        //    onclick="window.open('https://google.com','popup','width=600,height=600'); return false;"
                        //    >Download</a>`;
                    // }
                // }
            ],
            destroy: true  // I think some clean up is happening here
        }
    )

    

    if (queryRequests && queryResults === null) {
        fetch_query_results(queryRequests)
    }
    
    return (
        <div>
            
            {
                queryResults ?
                <div className={qview ? '' : 'is-sr-only'}>
                    <table id="table-to-xls" className="display" width="100%" ref={tableRef}></table>
                </div>
                    
                    :

                    <div style={{
                        width: '50%',
                        margin: '0 25%',
                        textAlign: 'center'
                    }}>
                        <div className='subtitle'>Gathering queries that people searched for...</div>
                        <progress className="progress is-medium is-link mt-4" max="10" >80%</progress>
                    </div>

            }

        </div>
        

    )
}

const mapStateToProps = state => ({
    queryRequests: state.queryReducer.queryRequests,
    queryResults: state.queryReducer.queryResults,
});

export default connect(mapStateToProps, {fetch_query_results})(QueryResults);