import React, { useState } from 'react'
import { connect } from "react-redux";
import { useNavigate  } from 'react-router-dom';
import { generate_query_request, load_segments } from "actions/queryActions"

const SearchForm = ({ load_segments, generate_query_request, user, segments, title, subtitle, props , queryRequests}) => {

    if (user && !segments) {
        load_segments(user?.searchsegment_set);
    }

    const navigate = useNavigate();
    
    const [errMsg, setErrMsg] = useState('')
    const [segmentList, setSegmentList] = React.useState([])
    const [keyword, setKeyword] = React.useState('')

    const handleSelect = (e) => {
        const { value } = e.target
        let is_exist = segmentList.includes(value)
        
        if (is_exist) {
            setSegmentList(segmentList.filter(item => item !== value));
        } else {
            setSegmentList([...segmentList,value])
        }
        
    }

    const handleChange = (e) => {
        const { value } = e.target
        setKeyword(value)
    };


    const handleSubmit = (e) => {
		e.preventDefault();
        setErrMsg('')
        let seedKeyword = keyword
        let segmentStr = segmentList.toString()
        let queryReq = generate_query_request(
            keyword,
            segmentStr)


            queryReq.then(
                (value) => {
                    setKeyword('')
                    setSegmentList([])
                    let str_v = value.detail || 'ok'
                    if (str_v.includes('throttled')) {
                        setErrMsg('Rate limit exceeded. Please')
                    } else {
                        navigate(`/kuery/?q=${seedKeyword}`);

                    }
                    
                },
                (reason) => {
                    setErrMsg(reason)
                },
            );
    };


    return (

        <div className="mt-6">
            <section className="section my-2">
                    <div className="has-text-centered mb-2">
                        <p className="title mb-6">{title}</p>
                        <p className="subtitle mb-6">{subtitle}</p>
                    </div>
                    <div className="container">
                        <div className="columns is-justify-content-center">
                            <div className="column is-6-tablet is-5-desktop is-4-widescreen is-3-full">
                                {errMsg.length > 0 &&
                                    <div className="notification is-small is-link is-light is-size-6 py-2 px-2 has-text-left">
                                        {errMsg}
                                        <a
                                            className="notification is-small is-link is-light is-size-6 py-2 px-2 has-text-left"
                                            href="/login"
                                            >Login
                                        </a>
                                    </div>
                                }
                            <form className="box p-5">
                                <label className="is-block mb-4">
                                <span className="is-block mb-2">Keyword</span>
                                <input
                                    name="keyword"
                                    className="input"
                                    type="text"
                                    placeholder="Enter your keyword..."
                                    value={keyword.keyword}
                                    onChange={handleChange}
                                />
                                </label>
                                <label className="is-block mb-4">
                                <span className="is-block mb-2">Choose a segment (up to 2)</span>
                                <div className="select">
                                <select onChange={e => handleSelect(e)}>
                                    <option>{segmentList.length ? `${segmentList.length} segments` : 'Select segments'}</option>
                                    
                                    {segments && Object.keys(segments).map(value => {
                                        
                                        return <option value={value} key={value}>
                                                    {segmentList.includes(segments[value]) ?
                                                    `✔ ${segments[value]}`
                                                    : 
                                                    `${segments[value]}`
                                                    }
                                                </option>
                                    
                                    })
                                    }
                                        <option value="30">{segmentList.includes("30") ? `✔ Audience groups` : `Audience groups`}</option>
                                        <option value="31">{segmentList.includes("31") ? `✔ Audience terms` : `Audience terms`}</option>
                                        <option value="32">{segmentList.includes("32") ? `✔ Body` : `Body`}</option>
                                        <option value="33">{segmentList.includes("33") ? `✔ Collection` : `Collection`}</option>
                                        <option value="34">{segmentList.includes("34") ? `✔ Compare` : `Compare`}</option>
                                        <option value="35">{segmentList.includes("35") ? `✔ Comparison` : `Comparison`}</option>
                                        <option value="36">{segmentList.includes("36") ? `✔ Comparison Research` : `Comparison Research`}</option>
                                        <option value="37">{segmentList.includes("37") ? `✔ Diet` : `Diet`}</option>
                                        <option value="38">{segmentList.includes("38") ? `✔ Digital product` : `Digital product`}</option>
                                        <option value="39">{segmentList.includes("39") ? `✔ Doc Format` : `Doc Format`}</option>
                                        <option value="40">{segmentList.includes("40") ? `✔ Education` : `Education`}</option>
                                        <option value="41">{segmentList.includes("41") ? `✔ Educator` : `Educator`}</option>
                                        <option value="42">{segmentList.includes("42") ? `✔ Exercise` : `Exercise`}</option>
                                        <option value="43">{segmentList.includes("43") ? `✔ Exercise terms` : `Exercise terms`}</option>
                                        <option value="44">{segmentList.includes("44") ? `✔ Federal Holidays` : `Federal Holidays`}</option>
                                        <option value="45">{segmentList.includes("45") ? `✔ Fitness` : `Fitness`}</option>
                                        <option value="46">{segmentList.includes("46") ? `✔ Freshness` : `Freshness`}</option>
                                        <option value="47">{segmentList.includes("47") ? `✔ General Audience` : `General Audience`}</option>
                                        <option value="48">{segmentList.includes("48") ? `✔ Global Holidays` : `Global Holidays`}</option>
                                        <option value="49">{segmentList.includes("49") ? `✔ Health Conditions` : `Health Conditions`}</option>
                                        <option value="50">{segmentList.includes("50") ? `✔ Images` : `Images`}</option>
                                        <option value="51">{segmentList.includes("51") ? `✔ Instructive Info` : `Instructive Info`}</option>
                                        <option value="52">{segmentList.includes("52") ? `✔ Local` : `Local`}</option>
                                        <option value="53">{segmentList.includes("53") ? `✔ Local terms` : `Local terms`}</option>
                                        <option value="54">{segmentList.includes("54") ? `✔ Locally` : `Locally`}</option>
                                        <option value="55">{segmentList.includes("55") ? `✔ Meals` : `Meals`}</option>
                                        <option value="56">{segmentList.includes("56") ? `✔ Niche Audience` : `Niche Audience`}</option>
                                        <option value="57">{segmentList.includes("57") ? `✔ Nutrition` : `Nutrition`}</option>
                                        <option value="58">{segmentList.includes("58") ? `✔ Official Education` : `Official Education`}</option>
                                        <option value="59">{segmentList.includes("59") ? `✔ Online action` : `Online action`}</option>
                                        <option value="60">{segmentList.includes("60") ? `✔ Online Tool` : `Online Tool`}</option>
                                        <option value="61">{segmentList.includes("61") ? `✔ Parts` : `Parts`}</option>
                                        <option value="62">{segmentList.includes("62") ? `✔ Price references` : `Price references`}</option>
                                        <option value="63">{segmentList.includes("63") ? `✔ Product Benefits` : `Product Benefits`}</option>
                                        <option value="64">{segmentList.includes("64") ? `✔ Promotion` : `Promotion`}</option>
                                        <option value="65">{segmentList.includes("65") ? `✔ Questions` : `Questions`}</option>
                                        <option value="66">{segmentList.includes("66") ? `✔ Shopping` : `Shopping`}</option>
                                        <option value="67">{segmentList.includes("67") ? `✔ Shopping Holidays` : `Shopping Holidays`}</option>
                                        <option value="68">{segmentList.includes("68") ? `✔ Sites extentions` : `Sites extentions`}</option>
                                        <option value="69">{segmentList.includes("69") ? `✔ Skin Care/Beauty` : `Skin Care/Beauty`}</option>
                                        <option value="70">{segmentList.includes("70") ? `✔ Technical characteristics` : `Technical characteristics`}</option>
                                        <option value="71">{segmentList.includes("71") ? `✔ Time` : `Time`}</option>
                                        <option value="72">{segmentList.includes("72") ? `✔ Time terms` : `Time terms`}</option>
                                        <option value="73">{segmentList.includes("73") ? `✔ Visual Art` : `Visual Art`}</option>


                                </select>
                                </div>
                                </label>
                                

                                <div className="mb-4">
                                    <button
                                        className="button is-link px-4"
                                        onClick={handleSubmit}
                                    >
                                        Search
                                    </button>
                                
                                </div>
                                
                            </form>
                            </div>
                        </div>
                    </div>
                
            </section>

        </div>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    queryRequests: state.queryReducer.queryRequests,
    segments: state.queryReducer.segments
  });
  
  export default connect(mapStateToProps, { generate_query_request, load_segments })(SearchForm);