import React from 'react'
import { connect } from "react-redux";
import Welcome from 'components/Welcome';

const Kuery = ({ user }) => {
    return (
        <div>
            <Welcome />

        </div>

    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user
  });
  
export default connect(mapStateToProps, {})(Kuery);