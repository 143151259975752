import React, { useState } from 'react'
import { connect } from "react-redux";

const Welcome = ({}) => {
// http://jsfiddle.net/isak_b/hp6fj/

    const [tagName, setTagName ] = useState('')

    const toggleTag = (name) => {
        if (tagName === name){
            setTagName('')
        } else {
            setTagName(name)
        }
    };
    

    return (
        <div className="mt-6">
            <link href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet"
                integrity="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" crossorigin="anonymous"></link>
            
            <div className="has-text-left">
                <div className="mb-6">
                    <div className="title">Welcome</div>
                    <div className="is-size-4">
                        searchedwords.site let you combine a seed keyword with modifiers, and then collect all related words your target audience has searched for.
                    </div>
                    <div className="is-size-4">
                        <strong>Modifiers</strong> can be adjectives, materials, slang, shopping terms, verbs, questions and any word related to your seed keyword
                    </div>
                    <p className="is-size-4 my-2">
                        <strong>Examples ahead:</strong>
                    </p>
                    <p className="is-size-4">Keyword + Modifiers = Keyword lists</p>
                    <br />
                    <article className="panel is-link">
                        <p className="panel-heading" onClick={() => toggleTag("Muscle")}>
                            Muscle - click to view 102 keywords
                        </p>
                        <p className="panel-tabs">
                            <a className="is-active is-size-5">
                                <strong>Modifiers:</strong>  Protein, Carbohydrates, Fats, Vitamins, Minerals</a>
                        </p>
                        <div className={tagName === "Muscle" ? "panel-block" : "panel-block is-hidden"}>
                            muscle protein synthesis,muscle protein,muscle protein powder,muscle protein breakdown,
                            muscle protein shake,muscle protein name,muscle protein synthesis window,muscle protein synthesis after workout,
                            muscle protein synthesis meaning,muscle protein food,muscle proteins,muscle protein turnover,muscle protein for dogs,
                            muscle protein bar,muscle protein alignment,muscle protein synthesis definition,muscle protein synthesis graph,
                            beverly international ultimate muscle protein,earth fed muscle protein,max muscle protein powder,
                            ultimate muscle protein,muscle protein shakes,lean muscle protein shake,monster muscle protein,muscle carbohydrates are,
                            muscle carbohydrates glycogen,carbohydrates muscle recovery,carbohydrates muscle protein synthesis,
                            carbohydrates muscle hypertrophy,carbohydrates muscle cramps,carbohydrates muscle repair,carbohydrates muscle sparing,
                            carbohydrates muscle aches,muscle milk carbohydrates,calcium deposits under muscles carbohydrates,muscle building carbohydrates,
                            what builds muscle carbs or protein,muscle gaining carbohydrates,glycosyn max muscle carbs,pure muscle carbs,muscle carbs,
                            muscle carbohydrate metabolism,muscle fats,good fats muscle building,muscle gain healthy fats,does muscle eat fat,
                            is muscle fat good,do muscles consume fat,muscle facts,muscle facts for kids,muscle fat storage,muscle fastigheter,
                            muscle fast twitch,muscle facts ks2,muscle fascia,muscle fascicle,muscle fasciculation,muscle fast growth,muscle fatigue,
                            muscle fastigheter ab,muscle become fats,muscle vitamins,muscle vitamins supplements,muscle vitamins and minerals,
                            vitamins muscle growth,vitamins muscle cramps,vitamins muscle recovery,vitamins muscle pain,vitamins muscle spasms,
                            vitamins muscle twitching,vitamins muscle soreness,muscle vitamins for men,muscle vitamins for women,muscle vitamins for dogs,
                            muscle vitamins supplements women,best vitamins for weight loss and muscle gain,vitamins for muscle cramps,
                            vitamins for muscle growth,joint and muscle vitamins,wile e coyote leg muscle vitamins,tony horton muscle vitamins,
                            tight calf muscle vitamins,best muscle vitamins,kaged muscle vitamins,bone and muscle vitamins,muscle minerals,
                            minerals muscle cramps,muscle contraction minerals,muscle building minerals,muscle recovery minerals,
                            muscle relaxant minerals,muscle shoals minerals,muscle strength minerals,muscle pain minerals,muscle gain minerals,
                            muscle building vitamins and minerals,muscle cramps minerals,muscle twitch minerals,muscle tightness and minerals,
                            muscle contraction and minerals,muscle aches and minerals,muscle cramp minerals
                        </div>

                    </article>
                    <article className="panel is-warning">
                        <p className="panel-heading" onClick={() => toggleTag("Dress")}>
                            Dress - click to view 90 keywords
                        </p>
                        <p className="panel-tabs">
                            <a className="is-active is-size-5">
                                <strong>Modifiers:</strong>  Christmas, Independence Day, Easter, Diwali, Passover</a>
                        </p>
                        <div className={tagName === "Dress" ? "panel-block" : "panel-block is-hidden"}>
                        dress christmas tree,dress christmas,dress christmas party,dress christmas girl,
                            dress christmas day,dress christmas table,dress christmas shirts,dress christmas blue,
                            dress christmas long,dress christmas party uk,dress christmas tree for sale,
                            dress christmas santa,dress christmas ornaments,dress christmas sweater,
                            dress christmas tree sam's club,dress christmas outfits for women,
                            grace mitchell dress christmas tree,red dress christmas party,women sweater dress christmas,
                            men's dress christmas shirts,cocktail dress christmas party,dress christmas tree mannequin,
                            plaid dress christmas,how to make a dress christmas tree,girls dress christmas,
                            megan young new york gown independence day,dress code on independence day,
                            dress for independence day,dress independence day,fancy dress independence day,
                            dress for independence day dance,dress for independence day online,independence clothing day,
                            dress code for independence day,dress code for independence day in office,
                            dress up for independence day,dress ideas for independence day,dress design for independence day,
                            dress easter,dress easter purses for little girls,dress master,dress ester,
                            dress master private limited,dress master sewing machine,dress master tailors,
                            dress master battaramulla,dress master negombo,dress master wattala,dress master apparel pvt ltd,
                            far eastern matriarch's dress,leann rimes dress easter 2023 images,baby dress easter shoes girls with diamonds,
                            yellow dress easter,pink and blue floral dress easter,gypsy tradition dress eastern europe,
                            how to make a black dress easter,maternity dress easter,maxi dress easter,dress easter hats for women,
                            coral dress easter,dress easter pastel,dress easter shirts,easter dress toddler,easter dress girl,
                            easter dress baby girl,eastern dress,easter dress baby,easter dress 2023,easter dress up,
                            easter dress for women,shop easter dress for girls,indian traditional.dress diwali,dress code diwali,
                            dress for diwali,dress code for diwali,dress diwali,diwali dress ideas,diwali dress code,
                            diwali dress nyt,diwali dress for baby girl,diwali dress for 15 year girl,diwali dress for 13 year girl,
                            diwali dress myntra,diwali dress for baby boy,diwali dress name,dress for passover,passover dress code,
                            passover dress,passover dress up,baby passover dress
                        </div>

                    </article>
                    <article className="panel is-primary">
                        <p className="panel-heading" onClick={() => toggleTag("Acne")}>
                        Acne - click to view 101 keywords
                        </p>
                        <p className="panel-tabs">
                            <a className="is-active is-size-5">
                                <strong>Modifiers:</strong>  hair, diet, detox, blood, biology</a>
                        </p>
                        <div className={tagName === "Acne" ? "panel-block" : "panel-block is-hidden"}>
                        acne hairline,acne hair follicle,acne hair loss,acne hair growth,acne hair,
                            acne hair treatment,acne hairline back of neck,acne hair removal,acne hair shampoo,
                            aube hair acne 長岡京店,acne hair loss weight gain,acne hairline neck,acne hair loss irregular periods,
                            scalp acne hair loss,fungal acne hairline,shampoo for acne hairline,acne hair gel,
                            thyroid acne hair loss,hormonal acne diet,acne diet plan,acne diet natural treatment,
                            cystic acne diet,acne diet for teens,hormonal imbalance acne diet,hormonal cystic acne diet,
                            nodular acne diet,best acne diet,dr mcdougall acne diet,acne diet,acne diet meal plan,
                            acne diet pdf,acne diet reddit,acne diet recipes,acne dietitian,acne diets that work,
                            acne dietary supplements,acne diet book,acne diet food list,acne diet and nutrition,
                            acne diet chart,acne diet menu,acne diet triggers,acne dietary supplement,acne diets,
                            acne detox drink,acne detox,acne detox diet,acne detox facial,acne detox water,
                            acne detox juice,acne detox water for skin,acne detox smoothie,acne detox food,
                            acne detox mask,acne detox pills,acne detox tea,acne detoxificar,acne detox diet plan,
                            acne detox diet cleanse,acne detox bath,acne detox soap,medical medium acne detox,acne detox program,
                            safeguard acne detox,anti acne detox,acne blood test,acne blood,acne blood pressure medication,
                            acne blood clot,acne blood sugar,acne blood spot,acne blood work,acne blood vessels on face,
                            what causes blood acne,why is my acne filled with blood,acne blood pressure,acne blood under skin,
                            acne blood bump,acne blood blisters,acne spot dragon blood gel,hormonal acne blood test,
                            acne blood blister,inflamed cystic acne bloody removal videos,acne blood treatment,
                            hormonal acne blood work,cystic acne blood,face acne blood,acne blood tests,acne biology,
                            acne meaning in biology,what causes acne biology,acne biology articles,
                            why acne and pimples are common in adolescence short answer,eczema biology,
                            ace biology lab,ace biology,ace biology lab videos,ache biology,ace biology dvd,
                            ace biology videos,ace biology lab 1097
                        </div>

                    </article>
                    <div className="is-size-4">
                        Enter Keyword + Modifiers and get your audience's searched words, collected directly from search engines databases.
                    </div>
                    <div className="title my-6">Happy researching <i className="fa fa-bolt" aria-hidden="true"></i></div>
                    
                    
                    

                </div>
                
                {/* <div className="columns
                    is-justify-space-around
                    is-align-content-center"
                >


                    <div className="column is-6-tablet is-5-desktop is-4-widescreen is-3-full">
                        <div className="content">
                            <div className="is-size-3 is-underlined mb-4">
                                How to
                            </div>
                            <p><span className="subtitle">1. Enter keyword - </span>the base keyword you are focusing on.</p>
                            <p><span className="subtitle">2. Enter modifiers - </span>Any word.</p>
                            <ul>
                                <li>Modifiers can be adjectives, materials, slang, shopping terms, verbs, questions and any search term your audience might be searching for</li>
                                <li>Explore modifier examples with context tags</li>

                            </ul>
                            <p className="subtitle">3. Click search</p>
                            <p><span className="subtitle">4. Keywords list generated - </span>These searched words are audience queries collected directly from search engines' databases.</p>
                            


                        </div>

                    </div>
                    <div className="column is-6-tablet is-5-desktop is-4-widescreen is-3-full">
                        <div className="content">
                            <div className="is-size-3 is-underlined mb-4">
                                Context tags
                            </div>
                            <p className="subtitle">Click on tag to view modifier examples</p>

                            <div>
                                <span className="tag is-primary mr-4 mb-2 is-clickable" onClick={() => toggleTag("Audiences")}>Audiences</span>
                                <span className="tag is-light mr-4 mb-2 is-clickable" onClick={() => toggleTag("Shopping")}>Shopping</span>
                                <span className="tag is-success mr-4 mb-2 is-clickable" onClick={() => toggleTag("Holidays")}>Holidays</span>
                                <span className="tag is-link mr-4 mb-2 is-clickable" onClick={() => toggleTag("Questions")}>Questions</span>
                                <span className="tag is-info mr-4 mb-2 is-clickable" onClick={() => toggleTag("Freshness")}>Freshness</span>
                                <span className="tag is-warning mr-4 mb-2 is-clickable" onClick={() => toggleTag("Location")}>Location</span>
                                <span className="tag is-danger mr-4 is-clickable" onClick={() => toggleTag("Promotion")}>Promotion</span>
                                <span className="tag is-warning mr-4 mb-2 is-clickable" onClick={() => toggleTag("Visual ref.")}>Visual ref.</span>
                                <span className="tag is-info mr-4 mb-2 is-clickable" onClick={() => toggleTag("Education")}>Education</span>
                                <span className="tag is-danger mr-4 mb-2 is-clickable" onClick={() => toggleTag("Price ref.")}>Price ref.</span>
                                <span className="tag is-light mr-4 mb-2 is-clickable" onClick={() => toggleTag("Time ref.")}>Time ref.</span>


                            </div>
                            <br />
                            <div className="columns 
                                        is-justify-space-between
                                        is-align-content-center"
                            >
                                <div className={tagName === "Shopping" ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-light">
                                        <div className="message-header">
                                            <p>Shopping</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>pricing</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>final sale</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>lifetime</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>discount</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Holidays" ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-success">
                                        <div className="message-header">
                                            <p>Holidays</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>New Year</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>Labor day</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>Christmas</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>Black Friday</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Questions" ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-link">
                                        <div className="message-header">
                                            <p>Questions</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>how</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>what</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>when</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>where</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Location" ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-warning">
                                        <div className="message-header">
                                            <p>Location</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>nearby</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>near me</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>distance</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>around</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Freshness" ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-info">
                                        <div className="message-header">
                                            <p>Freshness</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>update</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>latest</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>version</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>brand new</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Audiences" ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-primary">
                                        <div className="message-header">
                                            <p>Audiences</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>begginers</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>parents</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>newborn</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>body builders</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Promotion" ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-danger">
                                        <div className="message-header">
                                            <p>Promotion</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>on sale</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>giveaway</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>coupon</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>freemuim</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Visual ref." ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-warning">
                                        <div className="message-header">
                                            <p>Visual ref.</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>landscape</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>illustration</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>imitation</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>abstraction</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Education" ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-info">
                                        <div className="message-header">
                                            <p>Education</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>college</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>academy</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>certification</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>masterclass</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Price ref." ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-danger">
                                        <div className="message-header">
                                            <p>Price ref.</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>cheap</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>budget</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>low-cost</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>cost-effective</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                                <div className={tagName === "Time ref." ? "column is-narrow" : "column is-narrow is-hidden"}>
                                    <article className="message is-light">
                                        <div className="message-header">
                                            <p>Time ref.</p>
                                        </div>
                                        <div className="message-body">
                                            <div className="board-item">
                                                <div className="board-item-content"><span>week</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>today</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>tonight</span></div>
                                            </div>
                                            <div className="board-item">
                                                <div className="board-item-content"><span>spring</span></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>

                            </div>

                        </div>


                    </div>



                </div> */}
                
                
            </div>
            
            
        </div>

        
    )
}

const mapStateToProps = state => ({});
  
  export default connect(mapStateToProps, {  })(Welcome);