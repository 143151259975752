export const QUERY_REQ = 'QUERY_REQ';
export const QUERY_REQ_FAIL = 'QUERY_REQ_FAIL';
export const RESET_QUERY_REQ = 'RESET_QUERY_REQ';
export const QUERY_REQ_RESULTS = 'QUERY_REQ_RESULTS';
export const QUERY_REQ_RESULTS_FAIL = 'QUERY_REQ_RESULTS_FAIL';
export const SELECTED_RESULTS = 'SELECTED_RESULTS';
export const QUERY_OUTLINE = 'QUERY_OUTLINE';
export const QUERY_ARTICLE = 'QUERY_ARTICLE';
export const QUERY_CONTENT_FAIL = 'QUERY_CONTENT_FAIL';
export const QUERY_CONTENT_RESET = 'QUERY_CONTENT_RESET';
export const USER_SEGMENTS = 'USER_SEGMENTS';