import {
    // RESET_AUTH,
    // LOGIN_SUCCESS,
    // LOGIN_FAIL,
    // USER_UPDATED_SUCCESS,
    // USER_UPDATED_FAIL,
    // USER_LOADED_FAIL,
    // AUTHENTICATED_SUCCESS,
    // AUTHENTICATED_FAIL,
    // PASSWORD_RESET_SUCCESS,
    // PASSWORD_RESET_FAIL,
    // PASSWORD_RESET_CONFIRM_SUCCESS,
    // PASSWORD_RESET_CONFIRM_FAIL,
    // ACTIVATION_SUCCESS,
    SIGNUP_SUCCESS,
    USER_LOADED_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_FAIL,
    LOGOUT,
    SUBSCRIBED,
} from "../actions/actionTypes.js"

const initialState = {
    subscribed: null,
    access: localStorage.getItem('access'),
    created: false,
    user: null
};

// user.email, user.firstName, user.plan, user.searchsegment

const AuthReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case SIGNUP_SUCCESS:
            return {
                ...state,
                created: true,
            }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload
            }
        case SIGNUP_FAIL:
            return {
                ...state,
                created: payload,
            }
        case LOGOUT:
            return {
                ...state,
                user: null
            }
        case ACTIVATION_FAIL:
            return {
                ...state
            }
        case SUBSCRIBED:
            return {
                ...state,
                subscribed: payload
            }
        default:
            return state
    }
};


export default AuthReducer;