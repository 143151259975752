import React from 'react'
import { connect } from "react-redux";

const ModifierBlock = ({ modifier, modifierData }) => {
    
    return (
        <div>
            <div className="box mb-6 mr-6">
                <div className="is-size-4 is-underlined has-text-weight-semibold">{modifier}</div>
                <div className="mb-5">
                {modifierData.map(value => {
                        return <div key={value}>
                                {value}
                        </div>

                    })
                    }
                    
                                
                </div>
            </div>
        </div>
        

    )
}

const mapStateToProps = state => ({
});
export default connect(mapStateToProps, {})(ModifierBlock);
