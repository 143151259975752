import axios from 'axios';
import {setAxiosAuthToken} from "./axiosUtility"
import {
    // RESET_AUTH,
    // LOGIN_SUCCESS,
    // ACTIVATION_SUCCESS,
    // ACTIVATION_FAIL,
    // PASSWORD_RESET_SUCCESS,
    // PASSWORD_RESET_FAIL,
    // PASSWORD_RESET_CONFIRM_SUCCESS,
    // PASSWORD_RESET_CONFIRM_FAIL,
    // LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    SUBSCRIBED,
    LOGOUT
} from './actionTypes';

let msg = "Please enter valid details."

export const setToken = token => dispatch => {
    setAxiosAuthToken(token);
    localStorage.setItem("access", token);
  };


export const update_user = (promotions) => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({ promotions });

        try {
            const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/promotions/`, body, config);
            return result.data
        } catch (err) {
            // console.log('createSubcribtion err: ', err)
            return err
        }
    }
    
        
};


export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };
        try {
            const result = await axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/users/me/`, config);
            // const segment = await axios.get(`${process.env.REACT_APP_DOMAIN}api/v1/edit_segment/`, config);
            const userObj = result.data
            // userObj.segments = segment.data.segments;
            // userObj.history = segment.data.history;
            // userObj.plan = segment.data.plan;


            
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: userObj
            });
            // after 8 sec
        } catch (error) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
    
        
};


export const login = (email, password) => async dispatch => {

    dispatch({
        type: LOGOUT
    })

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email, password });

    try {
        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/token/login/`, body, config);
        const { auth_token } = result.data;
        dispatch(setToken(auth_token));
        dispatch(load_user());
        
    } catch (err) {
        dispatch({
            type: LOGOUT,
        })
        return msg
    }

};


export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({ token: localStorage.getItem('access') });

        try {
            const response = await axios.post(`${process.env.REACT_APP_DOMAIN}auth/jwt/verify/`, body, config);
            
            if (response.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        } catch (error) {
            
            // console.log('error.data: ', error)
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        }

    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        })
    }
};

// re_password
export const signup = (first_name, last_name, email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ first_name, last_name, email, password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/users/`, body, config);
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        if (Object.keys(err.response.data).length === 1) {
            msg = Object.values(err.response.data)
        }
        dispatch({
            type: SIGNUP_FAIL,
            payload: msg[0][0]
        })
        
        return { error: msg[0][0] }
    }
    
    
};


export const logout = () => async dispatch => {
    if (!axios.defaults.headers.common["Authorization"]){
        axios.defaults.headers.common["Authorization"] = "Token " + localStorage.getItem('access');
    }
    try {
        await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/token/logout/`);

        dispatch({
            type: LOGOUT,
        });
        setAxiosAuthToken("");
        localStorage.removeItem("access");
    } catch (err) {
        return err
        // console.log('logout err: ', err)

    }
};


export const reset_link = (email, reset_action) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });

    try {
        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/users/${reset_action}/`, body, config);
        if (result.status === 204) {
            return result.status
        }
        
        
    } catch (err) {
        return err
        // console.log('reset_link err: ', err)
    }

};


export const apply_reset_link = (reset_field, uid, token, new_password, re_new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    
    let body = JSON.stringify({ uid, token, new_password, re_new_password });

    if (reset_field === 'email'){
        let new_email = new_password
        let re_new_email = re_new_password
        body = JSON.stringify({ uid, token, new_email, re_new_email });
    }


    if (reset_field === 'activate'){
        body = JSON.stringify({ uid, token });
        try {
            const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/users/activation/`, body, config);
            if (result.status === 204) {
                return result.status
            }
            
            
        } catch (err) {
            return err
            // console.log('apply_reset_link err: ', err)
        }
    } else {
        try {
            const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/users/reset_${reset_field}_confirm/`, body, config);
            if (result.status === 204) {
                return result.status
            }
            
            
        } catch (err) {
            return err
            // console.log('apply_reset_link err: ', err)
        }
    }

};


export const subscribe = (name, details , email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ name, details, email });
    
    try {
        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/subscribe/`, body, config);
        dispatch({
            type: SUBSCRIBED,
            payload: result.data.saved
        });

        return result.data
    } catch (err) {
        return err
        // console.log('createSubcribtion err: ', err)
    }
}



export const subscription = ( token=null ) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    let token_str = ''
    if (token){
        token_str = token
    }
    const body = JSON.stringify({ token_str });
    try {
        const result = await axios.post(`${process.env.REACT_APP_DOMAIN}api/v1/subscription/`, body, config);
        return result.data.order_confirmed
    } catch (err) {
        return err
        // console.log('subscription err: ', err)
    }
}
