import React from 'react'

const Terms = props => {

  return (
    <div>
      OVERVIEW - <strong>
			Terms and Conditions of use</strong>
      <br />
      <p>This is terms and conditions, please find <a href="/privacy-policy">privacy policy here</a></p>

      <br />
      <p>This website is operated by kuerysearch.com. Throughout the site, the terms “we”, “us” and “our”, refer to kuerysearch.com. kuerysearch.com offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.
      </p>
      <p>By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”, “Terms of use”, “Terms and conditions”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.
      </p>
      <p>Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service.
      </p>
      <p>Any new features or tools which are added to the current site shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.
      </p>
      <br />
      <p>1 – SITE TERMS</p>
      <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.
      </p>
      <p>You may not use our products for any illegal or unauthorised purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
      </p><p>You must not transmit any worms or viruses or any code of a destructive nature.
      </p><p>A breach or violation of any of the Terms will result in an immediate termination of your Services.
      </p>
      <br />
      <p>2 – GENERAL CONDITIONS</p>

      <p>We reserve the right to refuse service to anyone for any reason at any time.
      </p><p>You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.
      </p><p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.
      </p><p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.
      </p>
      <br />
      <p>3 – ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</p>

      <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.
      </p><p>This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site.
      </p>
      <br />
      <p>4 – MODIFICATIONS TO THE SERVICE AND PRICES</p>

      <p>Prices for our products are subject to change without notice.</p>
      <p>We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.
      </p>
      <br />
      <p>5 – PRODUCTS OR SERVICES</p>

      <p>AVAILABILITY: The Site may develop and change over time.
      </p><p>We, and (as applicable) our Third Party API Providers, may amend, update, add to, replace or withdraw elements of our Site at any time at our sole discretion.
      </p><p>While we try to ensure that our Site is normally available 24 hours a day, we shall not be liable if for any reason our Site is unavailable at any time or for any period.
      </p><p>You understand and agree that temporary interruptions of the Site may occur and that access to our Site may be dependent on your and third party equipment, software and communications networks, which are entirely beyond our control.
      </p>
      <p>PAID PLAN SUBSCRIPTION: Paid plan can be Basic plan, Standard plan or Professional plan purchased on the Site on a rolling [monthly] subscription (Basic, Standard, Professional Plan).
      </p><p>The Site sets out our charging structure, service levels and any other specific terms applicable for our Paid Plans.
      </p><p>If you subscribe to Paid Plan, you are agreeing to pay the published charges for your subscription period.
      </p><p>Your use of your Paid Plan may not commence until we are in receipt of cleared funds.
      </p><p>We shall be entitled to suspend or cancel your Paid Plan without further liability in the event of late payment.
      </p>
      <p>Your Paid Plan is for the set subscription period stated on the Site. On expiry of your subscription period, you will automatically be charged for a further subscription period at the prevailing rate unless you follow the online cancellation procedure before the new subscription starts.
      </p>
      <p>We may make changes to our charges at any time in our sole discretion for any Paid Plan subscription periods which have not already been paid for by such point.
      </p>
      <p>RESTRICTIONS: Each Paid Plan login is for a single, named individual and is non-transferrable and you may not share your logins.
      </p><p>You can share the reports within your organisation. In the event that we find out that logins have been shared, we shall be entitled to levy additional charges for the usage that has been made via the shared access to the Site, to suspend or terminate your Paid Plan subscription at our sole discretion.
      </p>
      <p>By purchasing a Paid Plan you warrant that you are entitled to do so and that all of the payment information you provide is 100% accurate. You shall be responsible for all applicable taxes and duties applicable to your purchase in your location. By making any purchase via our Site, you also warrant that (a) you are legally capable of entering into binding contracts; and (b) you are at least 18 years old; and (c) you are making your purchase in the course of a business and not as a consumer.
      </p>
      <p>We reserve the right to reject or cancel any Paid Plan subscription at any time for any reason by refunding your payment for any unused part of your subscription period as your sole remedy. Please note that rejection or cancellation for specific cause (e.g. breach of these Terms and Conditions) will not give rise to a refund. If you decide to cancel your Paid Plan, you may do so at any time by following the online cancellation procedure but no refund will be payable to you.
      </p>
      <p>DEPENDENCIES: We shall use reasonable endeavours to make the Site and the service reasonably available but time shall not be of the essence in respect to any stated response or remedy times and availability of the Site and is not warranted in any respect.
      </p>
      <p>We are also dependent on third parties to support certain functionality within the Site and the service. No warranties or liability is accepted for the performance of or availability of such third party functionality.
      </p>
      <p>Our choice of third parties services is at our sole discretion although we shall aim to select a provider with appropriate expertise and service levels to ensure a reasonable functional environment for the Site and the service.
      </p>
      <br />
      <p>6 – OPTIONAL TOOLS</p>

      <p>You acknowledge and agree that we provide access to such tools ”as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools.
      </p>We may provide you with access to third-party tools over which we neither monitor nor have any control nor input.
      <p>Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
      </p><p>We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service.
      </p>
      <br />
      <p>8 – THIRD-PARTY LINKS</p>

      <p>Certain content, products and services available via our Service may include materials from third-parties.
      </p><p>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.
      </p><p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party’s policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.
      </p>
      <br />
      <p>9 – PERSONAL INFORMATION</p>

      <p>Your submission of personal information through the site is governed by our Privacy Policy. Read here our Privacy Policy.
      </p>
      <br />
      <p>10 – ERRORS, INACCURACIES AND OMISSIONS
      </p>
      <p>Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions. We reserve the right to correct any errors, inaccuracies or omissions, and to remove or change or update information if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your request).
      </p><p>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.
      </p>
      <br />
      <p>11 – PROHIBITED USES
      </p>
      <p>In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.
      </p>
      <br />
      <p>14 – DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY
      </p>
      <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free.
      </p><p>We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.
      </p><p>You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you.
      </p><p>You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided ‘as is’ and ‘as available’ for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement.
      </p><p>In no case shall kuerysearch.com, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law.
      </p>
      <br />
      <p>15 – INDEMNIFICATION</p>

      <p>You agree to indemnify, defend and hold harmless kuerysearch.com and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.
      </p>
      <p>In addition to and without limiting your liability under these Terms and Conditions, you hereby agree to defend, indemnify and hold us harmless for all liabilities, claims, and expenses, including legal fees and costs that arise from any breach of these Terms and Conditions by you. Without limiting this indemnity, we may opt to take over conduct of our own defence of any claim or liability, in which event you will cooperate with us fully in any legal dispute or proceedings.
      </p><p>DURATION AND TERMINATION: These Terms and Conditions shall come into effect on the date on which you first visit our Site and shall continue in force for as long as you continue to visit our Site or subscribe to our Paid Plan, save where any obligations are stated to survive termination or expiry of these Terms and Conditions.
      </p><p>Your subscription to Paid Plan shall expire at the end of your current subscription period if either (a) you have opted to cancel it or (b) you do not pay to renew it.
      </p><p>We may without liability terminate or suspend your use of the Site at the end of their current paid subscription period with or without notice and for any reason.
      </p><p>On expiry, termination or suspension for any reason your right to use Paid Plan features shall immediately terminate, and you acknowledge and agree that we may immediately deactivate or delete any of your user generated content hosted via the Site.
      </p><p>In addition, we may without any liability to you terminate or suspend your Paid Plan at any time with immediate effect on notice to you in the event that you have breached any material provision of these Terms and Conditions.
      </p>
      <br />
      <p>16 – SEVERABILITY
      </p>
      <p>In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions.
      </p>
      <br />
      <p>17 – TERMINATION</p>

      <p>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.
      </p><p>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.
      </p><p>If in our sole judgement you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).
      </p>
      <br />
      <p>18 – ENTIRE AGREEMENT</p>

      <p>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.
      </p><p>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).
      </p><p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.
      </p>
      <br />
      <p>19 – GOVERNING LAW</p>

      <p>These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of Israel.
      </p>
      <br />
      <p>20 – CHANGES TO TERMS OF SERVICE</p>

      <p>You can review the most current version of the Terms of Service at any time at this page.
      </p><p>We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.
      </p>
      <br />

      <p>21 – CONTACT INFORMATION</p>
      <p>Questions about the Terms of Service should be sent to us at galdevops@gmail.com
      </p>

    </div>
  );

}

export default Terms