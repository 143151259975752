import 'bulma/css/bulma.min.css';
import './App.css';

import { Provider } from "react-redux";
import KueryHome from './pages/KueryHome';
import Layout from './hoc/Layout';
import PrivacyPolicy from './pages/PrivacyPolicy'
import Terms from './pages/Terms';
import About from 'pages/About';
import NotFound from 'pages/NotFound'
import Contact from 'pages/Contact'
import Test from 'pages/Test';
import ArticleAd from 'pages/ArticleAd'
import store from "./store";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

function App() {
  return (
    <Provider store={store}>
      <Layout>
        <Router>
          <Routes>
            <Route exact path="/" element={<KueryHome />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/test" element={<Test />} />
            <Route path="/empower-keyword-research" element={<ArticleAd />} />
          </Routes>
        </Router>
      </Layout>
    </Provider>

  );
}

export default App;


{/* 


import SurveyGform from 'components/SurveyForm';
import Subscribe from 'pages/Subscribe';
import Subscribed from 'pages/Subscribed';
import QueryContent from './components/QueryContent'
import Home from './pages/Home';
import Account from './pages/Account';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Kuery from './pages/Kuery';
import Activate from './pages/Activate';
import NewPassword from './pages/NewPassword';
import NewEmail from './pages/NewEmail';
import ResetPassword from './pages/ResetPassword';
import ResetEmail from './pages/ResetEmail';
import SegmentsUser from './components/SegmentsUser';
import Guide from './pages/Guide';

<Routes>
  <Route exact path="/" element={<Home />} />
  <Route path="/account" element={<Account />} />
  <Route path="/login"element={<Login />} />
  <Route exact path="signup" element={<Signup />} />
  <Route path="/kuery/:ri?" element={<Kuery />} />
  <Route path="/activate/:uid/:token" element={<Activate />} />
  <Route path="/password/reset/confirm/:uid/:token" element={<NewPassword />} />
  <Route path="/email/reset/confirm/:uid/:token" element={<NewEmail />} />
  <Route path="/password/reset" element={<ResetPassword />} />
  <Route path="/email/reset" element={<ResetEmail />} />
  <Route path="/segments" element={<SegmentsUser />} />
  <Route path="/guide" element={<Guide />} />
  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  <Route path="/terms" element={<Terms />} />
  <Route path="/query_content/:ri?" element={<QueryContent />} />
  <Route path="/subscribe" element={<Subscribe />} />
  <Route path="/subscribed/:ri?" element={<Subscribed />} />
  <Route path="/about" element={<About />} />
  <Route path="/survey" element={<SurveyGform />} />
  <Route path="*" element={<NotFound />} />
</Routes> */}
