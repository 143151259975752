import React from 'react'
import { CSVLink } from "react-csv";
import { connect } from "react-redux";

const DownloadData = ({ queryResults }) => {

    let csvData = [["Id", "Query", "Modifier", "Segment"]]
    csvData.push(...queryResults);



    return (
      <CSVLink
        className="button is-link-outline mr-4"
        filename={`kuerysearch_${csvData.length - 1}_results.csv`}
        data={csvData}
        >
        Download as CSV
      </CSVLink>


    );
}


const mapStateToProps = state => ({
    queryResults: state.queryReducer.queryResults,
});

export default connect(mapStateToProps, {})(DownloadData);