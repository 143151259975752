import React from 'react'
import SearchForm from '../components/QuerySearch/SearchForm';

const NotFound = props => {

  return (
    <div>
      <div className='has-text-centered'>
        <div className='title'>Oops...page not found</div>
        <br />
        <div className='subtitle'>Email us if you have any question -> galdevops@gmail.com</div>


      </div>

      <SearchForm
        subtitle='...or start your research here ⬇'
      />
      <br />

    </div>
  );

}

export default NotFound