import { React, useState } from 'react'
import { connect } from "react-redux";
import Logo from '../assets/SEARCHED.png'

const Navbar = () => {
    const [isActive, setIsActive] = useState(false)

    const toggle = () => {
        if (!isActive) {
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }

    return (

        <div>
            <nav className="navbar has-shadow is-fixed-top">
                <div className="navbar-brand">
                    <div className="theme">
                        <a href="/">
                            <img src={Logo} alt="site logo" className="navbar-logo"/>
                        </a>
                    </div>

                    <div className="menuItem columns">
                    <div className="column">
                            <a
                                className="button is-link-outline"
                                href="/contact"
                            >
                                Contact
                            </a>

                            </div>
                        
                    </div>
                </div>
            </nav>
        </div>
    )
}
const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(Navbar);