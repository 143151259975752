import React, { useState } from 'react'
import { connect, useDispatch } from "react-redux";
import QueryResults from 'components/QueryResults';
import GraphicQueryResults from 'components/GraphicQueryResults'
import SearchwordForm from 'components/QuerySearch/Searchword';
import DownloadData from 'components/DatasetDownload'
import Welcome from 'components/Welcome';

const KueryHome = ({ user, queryRequests, queryResults, resetKuery }) => {
// http://jsfiddle.net/isak_b/hp6fj/
    const dispatch = useDispatch()

    const [view, setView] = useState(true)
    const [tagName, setTagName ] = useState('')

    const toggleView = () => {
        setView((prev) => !prev);
    }

    const toggleTag = (name) => {
        setTagName(name)
    };
    

    return (
        <div>
            
            
            <SearchwordForm />
                    <div className="py-2">
                        {
                            queryRequests ?
                                <div>
                                    {queryResults && <div className="title">{queryResults.length} results found</div>}
                                    <div className="mb-5">
                                    {queryResults && <DownloadData />}
                                        <button
                                            className="button is-link"
                                            onClick={toggleView}
                                        >
                                            Graphic / Table view
                                        </button>





                                    </div>
                                    {queryRequests && <QueryResults qview={!view} />}
                                    {view && <GraphicQueryResults />}
                                    <div className='my-4'></div>
                                </div>
                                :
                                
                                <Welcome />
                                


                        }

                        
                    </div>

        </div>
        
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    queryRequests: state.queryReducer.queryRequests,
    queryResults: state.queryReducer.queryResults,
    resetKuery: state.queryReducer.resetKuery,
  });
  
  export default connect(mapStateToProps, {  })(KueryHome);