import React, { useEffect } from 'react'
import Footer from './Footer'
import Topbar from './Topbar'
import Navbar from './Navbar'
import { connect } from 'react-redux';
import { checkAuthenticated, load_user } from '../actions/authActions';

const Layout = props => {
    useEffect(() => {
        props.load_user();
    }, [props]);
    
    return(
        
        <>
        <div className="layout theme">
        <Navbar />
          <main>
            {props.children}
          </main>
        </div>
      <Footer />
        </>
    

    );
};

export default connect(null, { checkAuthenticated, load_user})(Layout);