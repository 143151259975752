import React from 'react'
import ContactForm from '../components/ContactForm';

const Contact = props => {

  return (
    <div>
      <div className='has-text-left mb-4'>
        <div className='title my-5'>Hi you</div>
        <p className="subtitle mt-3">Have a question / request? Found a bug?</p>
        <div className='subtitle'>Send details via form or Email me -> galdevops@gmail.com</div>


      </div>
                                        

      <ContactForm
        subtitle='...or start your research here ⬇'
      />

    </div>
  );

}

export default Contact