import React from "react"
import { connect } from "react-redux";
import ModifierBlock from "./QuerySearch/ModifierBlock";

const GraphicQueryResults = ({ queryResultsObj }) => {
    
    return (
        <div>
            
             
            {queryResultsObj && Object.keys(queryResultsObj).map((value) => {
                let segment = value
                return <div key={segment}>
                    <div className="title">{segment}</div>
                    <hr />
                <div className="is-flex 
                is-justify-content-flex-start
                is-flex-direction-row
                is-flex-wrap-wrap"  key={value}>
                    {Object.keys(queryResultsObj[segment]).map(value => {
                    let modifier = value
                    let modifierSuggestes = queryResultsObj[segment][modifier]
                    return <div key={modifier}>
                            <ModifierBlock
                                modifier={modifier}
                                modifierData={modifierSuggestes}
                            />
                        </div>
                    
                    })}

                </div>
                </div>
                
                })
            }
            

        </div>

    )
}

const mapStateToProps = state => ({
    queryResultsObj: state.queryReducer.queryResultsObj,
});

export default connect(mapStateToProps, {})(GraphicQueryResults);